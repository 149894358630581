import { MutableRefObject, useCallback, useRef } from 'react';
import { UnregisterCallback } from 'history';

export const useBackButtonBlocking = (visible, disableClose, hide): [() => void, () => void] => {
 const unblock: MutableRefObject<UnregisterCallback> = useRef(null);

 const handleBack = useCallback(
  (location, action: string): string | false | void => {
   if (action === 'POP' && visible && !disableClose) {
    hide();
    return false;
   }
  },
  [hide, visible, disableClose]
 );

 const registerBackButtonPrompt = useCallback(() => {
  unblock.current = null;
 }, []);

 const unregisterBackButtonPrompt = useCallback(() => {
  if (unblock.current) {
   unblock.current();
   unblock.current = null;
  }
 }, []);

 return [registerBackButtonPrompt, unregisterBackButtonPrompt];
};
