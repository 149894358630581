import { useCallback, useEffect } from 'react';
import { useModalState } from 'shared/components/modal/hooks/use-modal-state.hook';

export const useTrapFocus = (name, modalRef) => {
  const {visible} = useModalState(name);

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key !== 'Tab') {
      return;
    }

    const modalElements = modalRef.current ? modalRef.current.querySelectorAll('a[href], button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled]), [tabindex="-1"]') : [];

    if (!event.shiftKey && document.activeElement === modalElements[modalElements.length - 1]) {
      event.preventDefault();
      modalElements[0].focus();
    } else if (event.shiftKey && document.activeElement === modalElements[0]) {
      event.preventDefault();
      modalElements[modalElements.length - 1].focus();
    }
  }, []);

  useEffect(() => {
    if (onKeyDown && visible) {
      document.addEventListener('keydown', onKeyDown);
    }
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown, visible]);
};
