import { Subject } from 'rxjs/Subject';
import { useCallback, useEffect, useRef } from 'react';

import { useBackButtonBlocking } from 'shared/components/modal/hooks/use-back-button-blocking.hook';
import { useHideModal } from 'shared/components/modal/hooks/use-hide-modal.hook';
import { useModalState } from 'shared/components/modal/hooks/use-modal-state.hook';
import { useEscapeToClose } from 'shared/components/modal/hooks/use-escape-to-close.hook';
import { useTrapFocus } from 'shared/components/modal/hooks/use-trap-focus.hook';
import { useSubscription } from 'app/hooks/use-subscription.hook';

export const useShowAndHideFunctionality = (name: string, disableClose: boolean, outerOnShow, outerOnHide, visibilityChanges: Subject<boolean>, modalRef, outerOnClose) => {
  const {visible} = useModalState(name);
  const hide = useHideModal(name, disableClose);
  const modalTriggerRef = useRef(null);

  const [registerBackButtonPrompt, unregisterBackButtonPrompt] = useBackButtonBlocking(visible, disableClose, hide);

  const focusElement = useCallback(() => {
    modalTriggerRef.current = document.activeElement; // storing element we will move focus back to onHide
    modalRef.current.focus();
  }, []);

  const onShow = useCallback(() => {
    registerBackButtonPrompt();
    focusElement();
    if (outerOnShow) {
      outerOnShow();
    }
  }, [outerOnShow, registerBackButtonPrompt]);

  const onHide = useCallback(() => {
    unregisterBackButtonPrompt();
    if (modalTriggerRef.current) {
      modalTriggerRef.current.focus(); // Move focus back to element that triggered modal to show
      modalTriggerRef.current = null;
    }

    if (outerOnHide) {
      outerOnHide();
    }
  }, [outerOnHide, unregisterBackButtonPrompt]);

  useEscapeToClose(name, hide, outerOnClose);
  useTrapFocus(name, modalRef);
  useSubscription(visibilityChanges, isVisible => isVisible ? onShow() : onHide(), [onShow, onHide]);

  useEffect(() => {
    return () => unregisterBackButtonPrompt();
  }, [unregisterBackButtonPrompt]);
};
