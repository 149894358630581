import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Subject } from 'rxjs/Subject';

import { hideModal, HideModalAction, showModal, ShowModalAction } from 'shared/components/modal/modal.actions';
import { useSelector } from 'app/hooks/store/use-selector.hook';

interface UseModalHook {
  <Context = { [prop: string]: any }>(name: string): [((context?: Context) => ShowModalAction), (() => HideModalAction), Context, Subject<boolean>];
}

export const useModal: UseModalHook = <Context = { [prop: string]: any }>(name) => {
  const dispatch = useDispatch();
  const currentContext = useSelector<Context>(state => (state.modals[name]?.context || {}) as Context);
  const visibilityChanges = useSelector<Subject<boolean>>(state => state.modals[name]?.visibilityChanges);
  const show = useCallback((context?: Context) => dispatch(showModal(name, context)), [name]);
  const hide = useCallback(() => dispatch(hideModal(name)), [name]);
  return [show, hide, currentContext, visibilityChanges];
};

interface UseModalContextHook {
  <Context = { [prop: string]: any }>(name: string): Context;
}

export const useModalContext: UseModalContextHook = <Context = { [prop: string]: any }>(name) => {
  const [, , context] = useModal<Context>(name);
  return context;
};
