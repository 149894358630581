import { useCallback, useEffect } from 'react';
import { BIN_FORM_V2 } from 'app/globals/constants';

import { useModalState } from 'shared/components/modal/hooks/use-modal-state.hook';

export const useEscapeToClose = (name, hide, close) => {
  const {visible} = useModalState(name)

  const onKeyUp = useCallback((event: KeyboardEvent) => {
    if (visible && event.key === 'Escape') {
      event.preventDefault();

      if (name == BIN_FORM_V2 && close) {       
        close();
      }
      
      hide(); 
    }
  }, [hide, visible, name, close]);

  const handleKeyUp = useCallback((e: KeyboardEvent) => onKeyUp(e), [onKeyUp]);

  useEffect(() => {
    if (handleKeyUp) {
      document.addEventListener('keyup', handleKeyUp);
    }

    return () => document.removeEventListener('keyup', handleKeyUp);
  }, [handleKeyUp]);
}
