import { useCallback } from 'react';

import { useModal } from 'app/hooks/use-modal.hook';

export const useHideModal = (name: string, disableClose: boolean) => {
  const [, hideModal] = useModal(name);

  return useCallback(() => {
    if (!disableClose) {
      hideModal();
    }
  }, [disableClose, hideModal]);
}
