import { Subject } from 'rxjs/Subject';
import { useEffect } from 'react';

import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { registerModal, unregisterModal } from 'shared/components/modal/modal.actions';
import { useModalState } from 'shared/components/modal/hooks/use-modal-state.hook';

export const useRegisterOnMount = (name: string, hide: () => any, visibilityChanges: Subject<boolean>) => {
  const {visible} = useModalState(name)
  const dispatchRegisterModal = useDispatcher(registerModal);
  const dispatchUnregisterModal = useDispatcher(unregisterModal);
  useEffect(() => {
    dispatchRegisterModal(name, visibilityChanges);

    return () => {
      if (visible) {
        hide();
      }
      dispatchUnregisterModal(name);
    };
  }, []);

}
