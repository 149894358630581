import { useLayoutEffect, useMemo } from 'react';
import { Subject } from 'rxjs/Subject';

import { useModalState } from 'shared/components/modal/hooks/use-modal-state.hook';

export const useVisibilityChangesSubject = (name: string): Subject<boolean> => {
  const {visible} = useModalState(name)
  const visibilityChanges = useMemo(() => new Subject<boolean>(), []);

  useLayoutEffect(() => visibilityChanges.next(visible), [visible]);

  return visibilityChanges;
};
